import { useState } from 'react';
import SeleccionMotivos from '../seleccion-motivos/SeleccionMotivos';
import VerificarRespuesta from '../verificar-respuesta/VerificarRespuesta';

const MisEmocionesDelDia = (props) => {
  const { emocionesSeleccionadas, setModal } = props;
  const [motivosSeleccionados, setMotivosSeleccionados] = useState();
  const [step, setStep] = useState(1);

  return (
    <div className="w-100">
      {step === 1 && (
        <SeleccionMotivos
          motivosSeleccionados={motivosSeleccionados}
          setMotivosSeleccionados={setMotivosSeleccionados}
          setModal={setModal}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <VerificarRespuesta
          emocionesSeleccionadas={emocionesSeleccionadas}
          motivosSeleccionados={motivosSeleccionados}
          setModal={setModal}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default MisEmocionesDelDia;
