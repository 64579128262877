import React from "react";
import {
  Body1,
  Card,
  CardFooter,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";

import styles from "./NoticiasEventosCardSkeleton.module.css";

const NoticiasEventosCardSkeleton = () => {
  return (
    <Card className={`p-0 ${styles.cardDiv}`}>
      <Skeleton>
        <SkeletonItem size={120} className={`${styles.skeletonImg}`} />
      </Skeleton>
      <Body1 className="p-3">
        <div className="flex-fill h-100">
          <Skeleton>
            <SkeletonItem size={16} />
          </Skeleton>
        </div>
      </Body1>
      <CardFooter className="p-3">
        <div className="flex-fill h-100">
          <Skeleton>
            <SkeletonItem className={`${styles.skeletonButton}`} />
          </Skeleton>
        </div>
      </CardFooter>
    </Card>
  );
};

export default NoticiasEventosCardSkeleton;
