import aprendizIcon from '../../../../assets/images/dt-mood/insignias/Aprendiz.svg';
import capitanIcon from '../../../../assets/images/dt-mood/insignias/Capitan.svg';
import especialistaIcon from '../../../../assets/images/dt-mood/insignias/Especialista.svg';
import pilotoIcon from '../../../../assets/images/dt-mood/insignias/Piloto.svg';
import pilotoDeMandoIcon from '../../../../assets/images/dt-mood/insignias/Piloto-de-mando.png';

export const awardsList = [
  {
    badge_id: 1,
    badge: 'Aprendiz',
    image: aprendizIcon,
  },
  {
    badge_id: 2,
    badge: 'Especialista',
    image: especialistaIcon,
  },
  {
    badge_id: 3,
    badge: 'Piloto',
    image: pilotoIcon,
  },
  {
    badge_id: 4,
    badge: 'Piloto de Mando',
    image: pilotoDeMandoIcon,
  },
  {
    badge_id: 5,
    badge: 'Capitán Espacial',
    image: capitanIcon,
  },
];
