import Swal from 'sweetalert2';

import thumbsUp from '../../../assets/images/dt-mood/alerts/thumbs-up.png';

const showEmocionesSuccessAlert = () => {
  Swal.fire({
    title: '¡Gracias por <br /> compartir tus emociones!',
    text: 'Esto nos permite estar más cerca',
    imageUrl: thumbsUp,
    imageWidth: 64,
    imageAlt: 'thumbs-up',
    confirmButtonText: 'Bien hecho',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title',
      htmlContainer: 'sweet-text',
      image: 'mt-5',
    },
  });
};

const showEmocionesWarningAlert = () => {
  Swal.fire({
    title: '¡Has alcanzado <br /> tu límite diario en sentimientos diarios!',
    text: 'Vuelve mañana para seguir ganando puntos',
    confirmButtonText: 'Cerrar',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title mt-5',
      htmlContainer: 'sweet-text',
    },
  });
};

export { showEmocionesSuccessAlert, showEmocionesWarningAlert };
