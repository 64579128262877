export const listaInsignias = {
  awards: [
    {
      id: '',
      badge_id: 1,
      badge: 'Aprendiz',
      description: '30 interacciones',
      image: 'https://dtmood-stag.s3.amazonaws.com/media/badges/Aprendiz.apng',
      date: ''
    },
    {
      id: '',
      badge_id: 2,
      badge: 'Especialista',
      description: '60 interacciones',
      image: 'https://dtmood-stag.s3.amazonaws.com/media/badges/Especialista.apng',
      date: ''
    },
    {
      id: '',
      badge_id: 3,
      badge: 'Piloto',
      description: '90 interacciones',
      image: 'https://dtmood-stag.s3.amazonaws.com/media/badges/Piloto.apng',
      date: ''
    },
    {
      id: '',
      badge_id: 4,
      badge: 'Piloto de Mando',
      description: '120 interacciones',
      image: 'https://dtmood-stag.s3.amazonaws.com/media/badges/Piloto-de-mando.apng',
      date: ''
    },
    {
      id: '',
      badge_id: 5,
      badge: 'Capitán Espacial',
      description: '150 interacciones',
      image: 'https://dtmood-stag.s3.amazonaws.com/media/badges/Capitan.apng',
      date: ''
    }
  ]
}
