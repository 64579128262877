import { useEffect } from "react";
import {
  Card,
  Body1,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import {
  ArrowCircleRightRegular,
  ArrowCircleRightFilled,
} from "@fluentui/react-icons";
import { useSelector } from "react-redux";

import styles from "./SeleccionarPuntoContacto.module.css";
import useApi from "../../../../../hooks/useApi";

const SeleccionarPuntoContacto = (props) => {
  const { setStep, setPuntosSeleccionados } = props;
  const { data: misPuntosContacto, loading, getData } = useApi();
  const SkeletonArray = [1, 2, 3];
  let userSession = useSelector((state) => state.authentication.userSession);
  const apiUrl = `/api/contactpointandsubs/${userSession.clientId}/list/`;

  useEffect(() => {
    getData(apiUrl);
  }, []);

  const selectPuntoContacto = (id) => {
    const selected = misPuntosContacto.find((item) => item.id === id);
    setPuntosSeleccionados(selected);
    setStep(2);
  };

  return (
    <>
      <div className="flex-fill mb-5">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 1
        </p>

        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 text-dark-dtmood">
          Mis evaluaciones
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 text-blue-dtmood">
          Selecciona una categoría
        </p>

        <div className="mx-1 mt-4">
          {loading &&
            SkeletonArray.map((item, index) => {
              return (
                <Card className="w-100 mb-3">
                  <Body1>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-fill h-100">
                        <Skeleton>
                          <SkeletonItem size={16} className="my-2" />
                        </Skeleton>
                      </div>
                    </div>
                  </Body1>
                </Card>
              );
            })}
          {misPuntosContacto &&
            !loading &&
            misPuntosContacto.map((item, index) => {
              return (
                <Card
                  className={`w-100 mb-3 cursor-pointer ${styles.optionCard}`}
                  key={index}
                  onClick={() => selectPuntoContacto(item.id)}
                >
                  <Body1>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="font-16 font-medium text-gray-dtmood ms-1">
                        {item.name}
                      </div>
                      <ArrowCircleRightFilled
                        className={`${styles.filledIcon} ${styles.forwardArrow}`}
                      />
                      <ArrowCircleRightRegular
                        className={`${styles.outlinedIcon} ${styles.forwardArrow}`}
                      />
                    </div>
                  </Body1>
                </Card>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SeleccionarPuntoContacto;
