import { useState } from "react";
import { SeleccionarMotivo, SeleccionarConducta } from "../../";

const PitazoStepper = () => {
  const [anonymous, setAnonymous] = useState(true);
  const [motivosSeleccionados, setMotivosSeleccionados] = useState();
  const [step, setStep] = useState(1);

  return (
    <div className="w-100">
      {step === 1 && (
        <SeleccionarMotivo
          anonymous={anonymous}
          setAnonymous={setAnonymous}
          setMotivosSeleccionados={setMotivosSeleccionados}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <SeleccionarConducta
          anonymous={anonymous}
          motivosSeleccionados={motivosSeleccionados}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default PitazoStepper;
