import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUpdatedMiDia: false,
};

export const miDiaSlice = createSlice({
  name: 'miDia',
  initialState,
  reducers: {
    ChangeMiDia: (state, action) => {
      state.isUpdatedMiDia = action.payload;
    },
  },
});

export const { ChangeMiDia } = miDiaSlice.actions;
export default miDiaSlice.reducer;
