import { Body1, Button, Card, CardFooter } from "@fluentui/react-components";
import styles from "./NoticiasEventosCard.module.css";

const NoticiasEventosCard = (props) => {
  const { item, setSelected } = props;

  const handleClick = () => {
    setSelected(item);
  };

  return (
    <Card className="p-0">
      <img alt={item.id} src={item.image} className={styles.dtmoodCardImage} />
      <Body1 className="p-3">
        <div className="flex-fill h-100">
          <h6 tag="h5">{item.title}</h6>
        </div>
      </Body1>
      <CardFooter className="p-3">
        <Button appearance="primary" onClick={handleClick}>
          Leer más
        </Button>
      </CardFooter>
    </Card>
  );
};

export default NoticiasEventosCard;
