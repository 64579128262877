import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUpdatedPuntosAcumulados: false,
};

export const PuntosAcumuladosSlice = createSlice({
  name: "puntosAcumulados",
  initialState,
  reducers: {
    ChangePuntosAcumulados: (state, action) => {
      state.isUpdatedPuntosAcumulados = action.payload;
    },
  },
});

export const { ChangePuntosAcumulados } = PuntosAcumuladosSlice.actions;
export default PuntosAcumuladosSlice.reducer;
