import { useContext, useEffect } from "react";
// import { Welcome } from "./sample/Welcome";
import { TeamsFxContext } from "./Context";
// import config from "./sample/lib/config";
import Login from "../modules/auth/views/login/Login";
import Dashboard from "../modules/home/views/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { getDtMoodSession } from "../modules/auth/services";
import { ChangeAuthentication } from "../modules/auth/store/authentication/AuthenticationSlice";
import { useTokenDtMood } from "../hooks/useTokenDTMood";

// const showFunction = Boolean(config.apiName);

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const dispatch = useDispatch();
  const { validarTokenDtMood } = useTokenDtMood();

  let userSession = useSelector((state) => state.authentication.userSession);

  if (!userSession) {
    userSession = getDtMoodSession();
    dispatch(ChangeAuthentication(userSession));
  }

  useEffect(() => {
    validarTokenDtMood();
  }, []);

  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {/* <Welcome showFunction={showFunction} /> */}
      {!userSession ? <Login /> : <Dashboard />}
    </div>
  );
}
