import React, { useState, memo, useEffect } from "react";
import {
  Tab,
  TabList,
  Dialog,
  DialogSurface,
  DialogContent,
  DialogActions,
  DialogTrigger,
  DialogBody,
  Button,
  useRestoreFocusTarget,
} from "@fluentui/react-components";

import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import styles from "./Dashboard.module.css";
import NoticiasEventos from "../noticias-y-eventos/NoticiasEventos";
import {
  ContactPointsCard,
  ModalInsigniaObtenida,
  NoticiasEventosCardHome,
  PreguntasFrecuentes,
  QuickFeedbackCard,
  EnviarPitazoCard,
  FraseDelDia,
  InformacionUsuario,
  SeleccionEmociones,
  MisInsignias,
  MiDia,
  PuntosAcumulados,
  PreguntasFrecuentesCard,
} from "../../components";
import { ChangeAwardStatus } from "../../store/award/AwardSlice";
import { useTokenDtMood } from "../../../../hooks/useTokenDTMood";

const Dashboard = () => {
  const [selectedValue, setSelectedValue] = useState("home");
  const [open, setOpen] = useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const [wonAward, setWonAward] = useState();
  const [celebration, setCelebration] = useState(false);
  const { validarTokenDtMood } = useTokenDtMood();

  const onTabSelect = (event, data) => {
    setSelectedValue(data.value);
  };

  let awardGotten = useSelector((state) => state.award.awardGotten);
  const dispatch = useDispatch();

  useEffect(() => {
    validarTokenDtMood();
  }, []);

  useEffect(() => {
    if (awardGotten) {
      setCelebration(false);
      setWonAward(awardGotten);
      setOpen(true);
    }
  }, [awardGotten]);

  const hideCelebration = () => {
    setCelebration(false);
    setOpen(false);
    dispatch(ChangeAwardStatus(undefined));
  };

  const HomeTab = memo(() => (
    <div
      role="tabpanel"
      aria-labelledby="Home"
      className={`p-3 ${styles.containerDiv}`}
    >
      <div className="w-100">
        <Row className="mt-1">
          <Col xs="12" sm="12" md="12" lg="8" className="d-flex mt-2 mt-lg-0">
            <InformacionUsuario />
          </Col>
          <Col xs="12" sm="12" md="12" lg="4" className="d-flex mt-2 mt-lg-0">
            <FraseDelDia />
          </Col>
        </Row>
        <Row className="mt-lg-4">
          <Col
            xs="12"
            sm="12"
            md="6"
            lg="3"
            className="d-flex mt-2 mt-lg-0"
            onClick={() => setSelectedValue("noticiasEventos")}
          >
            <NoticiasEventosCardHome />
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" className="d-flex mt-2 mt-lg-0">
            <ContactPointsCard />
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" className="d-flex mt-2 mt-lg-0">
            <QuickFeedbackCard />
          </Col>
          <Col xs="12" sm="12" md="6" lg="3" className="d-flex mt-2 mt-lg-0">
            <EnviarPitazoCard />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex mt-2 mt-lg-0">
            <SeleccionEmociones />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex mt-2 mt-lg-0">
            <MisInsignias />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex mt-2 mt-lg-0">
            <MiDia />
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex">
            <Row className="m-0 p-0 w-100">
              <Col xs="12" sm="12" md="12" lg="12" className="m-0 p-0">
                <PuntosAcumulados />
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" className="m-0 p-0 mt-4">
                <PreguntasFrecuentesCard setSelectedValue={setSelectedValue} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  ));

  const NoticiasEventosTab = memo(() => (
    <div
      role="tabpanel"
      aria-labelledby="NoticiasEventos"
      className={`p-3 ${styles.containerDiv}`}
    >
      <NoticiasEventos />
    </div>
  ));

  const PreguntasFrecuentesTab = memo(() => (
    <div
      role="tabpanel"
      aria-labelledby="PreguntasFrecuentes"
      className={`p-3 ${styles.containerDiv}`}
    >
      <PreguntasFrecuentes />
    </div>
  ));

  return (
    <>
      <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
        <Tab id="Home" value="home">
          Home
        </Tab>
        <Tab id="NoticiasEventos" value="noticiasEventos">
          Noticias y eventos
        </Tab>
        <Tab id="PreguntasFrecuentes" value="preguntasFrecuentes">
          Preguntas frecuentes
        </Tab>
      </TabList>
      <div>
        {selectedValue === "home" && <HomeTab />}
        {selectedValue === "noticiasEventos" && <NoticiasEventosTab />}
        {selectedValue === "preguntasFrecuentes" && <PreguntasFrecuentesTab />}
      </div>

      <Dialog
        open={open}
        onOpenChange={(event, data) => {
          setOpen(data.open);
        }}
        onDismiss={{ ...restoreFocusTargetAttribute }}
        modalType="non-modal"
      >
        <DialogSurface className="p-0 px-1">
          <DialogBody>
            <DialogContent className="p-0">
              <ModalInsigniaObtenida
                celebrate={celebration}
                badgeId={wonAward}
              />
            </DialogContent>

            <DialogActions className="p-3 pt-0">
              <Button appearance="primary" onClick={() => setCelebration(true)}>
                ¡Celébralo!
              </Button>

              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  {...restoreFocusTargetAttribute}
                  onClick={() => hideCelebration()}
                >
                  Cerrar
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default Dashboard;
