const saveLocalAwards = (awards) => {
  window.localStorage.setItem("localAwards", JSON.stringify(awards));
};

const getLocalAwards = () => {
  const data = window.localStorage.getItem("localAwards");
  return data ? JSON.parse(data) : null;
};

const removeLocalAwards = () => {
  return window.localStorage.removeItem("localAwards");
};

export { saveLocalAwards, getLocalAwards, removeLocalAwards };
