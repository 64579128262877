import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUpdatedMisInsignias: false,
};

export const misInsigniasSlice = createSlice({
  name: 'misInsignias',
  initialState,
  reducers: {
    ChangeMisInsignias: (state, action) => {
      state.isUpdatedMisInsignias = action.payload;
    },
  },
});

export const { ChangeMisInsignias } = misInsigniasSlice.actions;
export default misInsigniasSlice.reducer;
