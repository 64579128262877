import { useState } from "react";
import axios from "axios";
import apiDtMood from "../modules/home/services/dtMoodApiHandler";

const useApi = (authenticated = true) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = authenticated ? apiDtMood : axios;

  const getData = (apiUrl) => {
    setLoading(true);
    setData(null);
    setError(null);

    api
      .get(apiUrl)
      .then((resp) => {
        setData(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const postData = (apiUrl, data) => {
    setLoading(true);

    api
      .post(apiUrl, data)
      .then((resp) => {
        setData(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const putData = (apiUrl, data) => {
    setLoading(true);

    api
      .put(apiUrl, data)
      .then((resp) => {
        setData(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return { data, loading, error, getData, postData, putData };
};

export default useApi;
