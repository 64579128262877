import Swal from 'sweetalert2';

import contactPoints from '../../../assets/images/dt-mood/iconos/contact-points-alert.png';

const showContactPointsSuccessAlert = () => {
  Swal.fire({
    title: '¡Gracias por tu evaluación!',
    text: 'Esto nos permite estar más cerca',
    imageUrl: contactPoints,
    imageWidth: 64,
    imageAlt: 'feedback',
    confirmButtonText: 'Bien hecho',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title',
      htmlContainer: 'sweet-text',
      image: 'mt-5',
    },
  });
};

const showContactPointsWarningAlert = () => {
  Swal.fire({
    title: '¡Has alcanzado <br /> tu límite diario en calificaciones!',
    text: 'Vuelve mañana para seguir ganando puntos',
    confirmButtonText: 'Cerrar',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title mt-5',
      htmlContainer: 'sweet-text',
    },
  });
};

export { showContactPointsSuccessAlert, showContactPointsWarningAlert };
