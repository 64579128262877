import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogSurface,
  DialogContent,
  DialogActions,
  DialogTrigger,
  DialogBody,
  Button,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
  useRestoreFocusTarget,
} from "@fluentui/react-components";

import styles from "./NoticiasEventos.module.css";
import useApi from "../../../../hooks/useApi";
import {
  NoticiasEventosCard,
  NoticiasEventosCardSkeleton,
} from "../../components";
import { ChangeAwardStatus } from "../../store/award/AwardSlice";
import { ChangeMiDia } from "../../store/mi-dia/MiDiaSlice";
import { useTokenDtMood } from "../../../../hooks/useTokenDTMood";

const NoticiasEventos = () => {
  const {
    data: newsData,
    loading: newsLoading,
    error: newsError,
    getData: getNewsData,
  } = useApi();
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    getData: getEventsData,
  } = useApi();
  const { data: interactionData, putData: putInteraction } = useApi();
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const dispatch = useDispatch();
  const { validarTokenDtMood } = useTokenDtMood();

  const newsEventsSkeletonData = [1, 2, 3, 4];
  let userSession = useSelector((state) => state.authentication.userSession);
  const clientId = userSession ? userSession.clientId : "";
  const newsUrl = `${process.env.REACT_APP_API_DTMOOD}/api/news/${clientId}/newsletter-list/`;
  const eventsUrl = `${process.env.REACT_APP_API_DTMOOD}/api/news/${clientId}/events-list/`;

  useEffect(() => {
    validarTokenDtMood();
    getNewsData(newsUrl);
    getEventsData(eventsUrl);
  }, []);

  useEffect(() => {
    if (selected) {
      setOpen(true);
      sendUserInteraction();
    }
  }, [selected]);

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }

    if (interactionData && interactionData.award.id !== "" && !open) {
      dispatch(ChangeAwardStatus(true));
      dispatch(ChangeMiDia(false));
    }
  }, [interactionData, open]);

  const sendUserInteraction = () => {
    const username = userSession ? userSession.username : "";
    const interactionUrl = `${process.env.REACT_APP_API_DTMOOD}/api/news/${username}/`;
    putInteraction(interactionUrl);
  };

  return (
    <>
      <h4 className="mb-4">Noticias</h4>

      <div className="w-100 m-1 border-top">
        <Row className="mt-1 pt-4">
          {newsLoading && (
            <>
              {newsEventsSkeletonData.map((item, index) => {
                return (
                  <Col
                    xs="6"
                    sm="6"
                    md="4"
                    lg="3"
                    className="d-flex"
                    key={index}
                  >
                    <NoticiasEventosCardSkeleton />
                  </Col>
                );
              })}
            </>
          )}
          {newsData && newsData.length > 0 && !newsLoading && (
            <>
              {newsData.map((item, index) => {
                return (
                  <Col
                    xs="6"
                    sm="6"
                    md="4"
                    lg="3"
                    className="d-flex"
                    key={index}
                  >
                    <NoticiasEventosCard
                      item={item}
                      setSelected={setSelected}
                    />
                  </Col>
                );
              })}
            </>
          )}
          {newsError && (
            <Col xs="6" sm="6" md="4" lg="3" className="d-flex">
              <MessageBar key="1" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Error</MessageBarTitle>
                  Error de conexión
                </MessageBarBody>
              </MessageBar>
            </Col>
          )}
        </Row>
      </div>

      <h4 className="mb-4 mt-5">Eventos</h4>

      <div className="w-100 m-1 border-top">
        <Row className="mt-1 pt-4">
          {eventsLoading && (
            <>
              {newsEventsSkeletonData.map((item, index) => {
                return (
                  <Col
                    xs="6"
                    sm="6"
                    md="4"
                    lg="3"
                    className="d-flex"
                    key={index}
                  >
                    <NoticiasEventosCardSkeleton />
                  </Col>
                );
              })}
            </>
          )}

          {eventsData && eventsData.length > 0 && !eventsLoading && (
            <>
              {eventsData.map((item, index) => {
                return (
                  <Col
                    xs="6"
                    sm="6"
                    md="4"
                    lg="3"
                    className="d-flex"
                    key={index}
                  >
                    <NoticiasEventosCard
                      item={item}
                      setSelected={setSelected}
                    />
                  </Col>
                );
              })}
            </>
          )}
          {eventsError && (
            <Col xs="6" sm="6" md="4" lg="3" className="d-flex">
              <MessageBar key="1" intent="error">
                <MessageBarBody>
                  <MessageBarTitle>Error</MessageBarTitle>
                  Error de conexión
                </MessageBarBody>
              </MessageBar>
            </Col>
          )}
        </Row>
      </div>

      <Dialog
        open={open}
        onOpenChange={(event, data) => {
          setOpen(data.open);
        }}
        onDismiss={{ ...restoreFocusTargetAttribute }}
      >
        <DialogSurface className="p-1">
          {selected && (
            <>
              <DialogBody className={`${styles.dialogBody}`}>
                <div className={`${styles.dialogImgDiv}`}>
                  <img
                    alt={selected.id}
                    src={selected.image}
                    className={styles.dtmoodCardImage}
                  />
                </div>

                <DialogContent className="p-0">
                  <div className="flex-fill h-100 p-3">
                    <h6 tag="h5">{selected.title}</h6>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{ __html: selected.body }}
                      />
                    </p>
                  </div>
                </DialogContent>

                <DialogActions className="p-3">
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      appearance="primary"
                      {...restoreFocusTargetAttribute}
                    >
                      Cerrar
                    </Button>
                  </DialogTrigger>
                </DialogActions>
              </DialogBody>
            </>
          )}
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default NoticiasEventos;
