import feliz from '../../../assets/images/dt-mood/emociones/feliz.svg';
import entusiasmado from '../../../assets/images/dt-mood/emociones/entusiasmado.svg';
import relajado from '../../../assets/images/dt-mood/emociones/relajado.svg';
import alegre from '../../../assets/images/dt-mood/emociones/alegre.svg';
import contento from '../../../assets/images/dt-mood/emociones/contento.svg';
import orgulloso from '../../../assets/images/dt-mood/emociones/orgulloso.svg';
import sorprendido from '../../../assets/images/dt-mood/emociones/sorprendido.svg';
import apenado from '../../../assets/images/dt-mood/emociones/apenado.svg';
import cansado from '../../../assets/images/dt-mood/emociones/cansado.svg';
import molesto from '../../../assets/images/dt-mood/emociones/molesto.svg';
import confundido from '../../../assets/images/dt-mood/emociones/confundido.svg';
import triste from '../../../assets/images/dt-mood/emociones/triste.svg';

export const listaEmociones = [
  {
    id: 1,
    name: 'Feliz',
    icon: feliz,
    selected: false,
  },
  {
    id: 2,
    name: 'Entusiasmado',
    icon: entusiasmado,
    selected: false,
  },
  {
    id: 3,
    name: 'Relajado',
    icon: relajado,
    selected: false,
  },
  {
    id: 4,
    name: 'Alegre',
    icon: alegre,
    selected: false,
  },
  {
    id: 5,
    name: 'Contento',
    icon: contento,
    selected: false,
  },
  {
    id: 6,
    name: 'Orgulloso',
    icon: orgulloso,
    selected: false,
  },
  {
    id: 7,
    name: 'Sorprendido',
    icon: sorprendido,
    selected: false,
  },
  {
    id: 8,
    name: 'Apenado',
    icon: apenado,
    selected: false,
  },
  {
    id: 9,
    name: 'Cansado',
    icon: cansado,
    selected: false,
  },
  {
    id: 10,
    name: 'Molesto',
    icon: molesto,
    selected: false,
  },
  {
    id: 11,
    name: 'Confundido',
    icon: confundido,
    selected: false,
  },
  {
    id: 12,
    name: 'Triste',
    icon: triste,
    selected: false,
  },
];
