import { useEffect } from "react";
import {
  Card,
  Body1,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Form, FormGroup, Label, Input } from "reactstrap";
import {
  ArrowCircleRightRegular,
  ArrowCircleRightFilled,
} from "@fluentui/react-icons";

import useApi from "../../../../../hooks/useApi";
import styles from "./SeleccionarMotivo.module.css";

const SeleccionarMotivo = (props) => {
  const { setStep, anonymous, setAnonymous, setMotivosSeleccionados } = props;
  const { data: misMotivos, loading, getData } = useApi();
  const SkeletonArray = [1, 2, 3];
  const apiUrl = `/api/denunciation/subs/`;

  useEffect(() => {
    getData(apiUrl);
  }, []);

  const selectMotivo = (id) => {
    const selected = misMotivos.find((item) => item.id === id);
    setMotivosSeleccionados(selected);
    setStep(2);
  };

  return (
    <>
      <div className="flex-fill mb-5">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 1
        </p>

        <Form>
          <FormGroup>
            <div className="d-flex justify-content-center">
              <Label
                for="exampleCustomSwitch"
                className="font-14 font-medium text-red-dtmood text-left me-2"
              >
                ¿Quieres ser Anónimo?
              </Label>
              <Input
                type="switch"
                id="exampleCustomSwitch"
                name="customSwitch"
                checked={anonymous}
                className={styles.customSwitch}
                onChange={() => setAnonymous(!anonymous)}
              />
            </div>
          </FormGroup>
        </Form>

        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 text-dark-dtmood">
          Selecciona las malas conductas <br /> relacionadas a tu motivo
        </p>

        <div className="mx-1 mt-4">
          {loading &&
            SkeletonArray.map((item, index) => {
              return (
                <Card className="w-100 mb-3">
                  <Body1>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-fill h-100">
                        <Skeleton>
                          <SkeletonItem size={16} className="my-2" />
                        </Skeleton>
                      </div>
                    </div>
                  </Body1>
                </Card>
              );
            })}

          {misMotivos &&
            !loading &&
            misMotivos.map((item, index) => {
              return (
                <Card
                  className={`w-100 mb-3 cursor-pointer ${styles.optionCard}`}
                  key={index}
                  onClick={() => selectMotivo(item.id)}
                >
                  <Body1>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="font-16 font-medium text-gray-dtmood ms-1">
                        {item.category}
                      </div>
                      <ArrowCircleRightFilled
                        className={`${styles.filledIcon} ${styles.forwardArrow}`}
                      />
                      <ArrowCircleRightRegular
                        className={`${styles.outlinedIcon} ${styles.forwardArrow}`}
                      />
                    </div>
                  </Body1>
                </Card>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SeleccionarMotivo;
