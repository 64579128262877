import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Body1, Textarea } from "@fluentui/react-components";
import { FormGroup, Label } from "reactstrap";

import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";
import { EmocionesContext } from "../../../context";
import styles from "./VerificarRespuesta.module.css";
import useApi from "../../../../../hooks/useApi";

const VerificarRespuesta = (props) => {
  const { setStep, emocionesSeleccionadas, motivosSeleccionados, setModal } =
    props;
  const { setEmocionesState } = useContext(EmocionesContext);
  const { data, loading, error, postData } = useApi();
  let userSession = useSelector((state) => state.authentication.userSession);
  const userName = userSession ? userSession.username : "";
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const state = data.award.id === "" ? "success" : "award";
      setEmocionesState({
        state,
        badge_id: data.award.badge_id,
      });
      dispatch(ChangeMiDia(false));
    }
    if (error) {
      setEmocionesState({
        state: "warning",
      });
    }
    setModal(false);
  }, [data, error]);

  const onSubmit = () => {
    const formData = formatData();
    postData(`/api/feelings/`, formData);
  };

  const formatData = () => {
    const totalEmociones = emocionesSeleccionadas.map((item) => {
      return item.id;
    });

    const totalMotivos = motivosSeleccionados.misMotivos.map((item) => {
      return item.id;
    });

    return {
      user: userName,
      comments: motivosSeleccionados.comments,
      reasons: totalMotivos,
      feelings: totalEmociones,
    };
  };

  return (
    <>
      <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
        Resumen
      </p>

      <p className="font-16 font-medium text-center text-color-dark-blue">
        Estas son tus emociones del día y tus motivos
      </p>

      <div className="d-flex justify-content-center">
        {emocionesSeleccionadas &&
          emocionesSeleccionadas.map((item, index) => {
            return (
              <div
                className="flex-fill justify-content-center m-2"
                key={index}
                style={{ maxWidth: "90px" }}
              >
                <div className="d-flex justify-content-center">
                  <img
                    key={item.id}
                    src={item.icon}
                    alt="icono-emociones"
                    className="align-self-center icon-card-emociones"
                  />
                </div>
                <div className="w-100 mt-2">
                  <p className="text-center font-medium text-color-dark-blue-2 font-12 m-0 p-0">
                    {item.name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>

      <div className="m-3 mx-1 mb-4">
        {motivosSeleccionados &&
          motivosSeleccionados.misMotivos.map((item, index) => {
            return (
              <FormGroup inline className="w-100" key={index}>
                <Card className={`w-100 mb-2 ${styles.cardRadio}`}>
                  <Body1 className="py-2 px-2">
                    <div
                      className={`d-flex align-items-center ${styles.checkDiv}`}
                    >
                      <input
                        name="reason"
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        checked
                        readOnly
                      />
                      <Label
                        htmlFor={item.id}
                        check
                        className="font-14 font-medium text-gray-dtmood ms-2"
                      >
                        {item.reason}
                      </Label>
                    </div>
                  </Body1>
                </Card>
              </FormGroup>
            );
          })}

        <FormGroup className="w-100">
          <Textarea
            name="comments"
            id="comments"
            cols="30"
            rows="4"
            placeholder="Puedes escribir otro motivo"
            className="mb-1 w-100"
            value={motivosSeleccionados.comments}
            readOnly
          />
        </FormGroup>
      </div>

      <div className="d-flex align-items-center justify-content-end mb-3">
        <Button
          appearance="primary"
          outline
          type="button"
          className="me-2"
          onClick={() => setStep(1)}
        >
          Regresar
        </Button>
        <Button
          appearance="primary"
          type="button"
          disabled={loading}
          onClick={onSubmit}
        >
          Finalizar
        </Button>
      </div>
    </>
  );
};

export default VerificarRespuesta;
