export const responseValues = [
  {
    value: 1,
    selected: false,
  },
  {
    value: 2,
    selected: false,
  },
  {
    value: 3,
    selected: false,
  },
  {
    value: 4,
    selected: false,
  },
  {
    value: 5,
    selected: false,
  },
];
