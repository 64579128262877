import { React } from "react";
import { Body1, Button, Card } from "@fluentui/react-components";
import { Row, Col } from "reactstrap";

import iconFaqs from "../../../../../assets/images/dt-mood/icon-faqs.png";
import styles from "./PreguntasFrecuentesCard.module.css";

const PreguntasFrecuentesCard = (props) => {
  const { setSelectedValue } = props;

  return (
    <Card className="w-100 p-0">
      <div className="p-3 border-bottom mb-0">
        <div className="container text-start">
          <div className="row align-items-center">
            <div className="col">
              <img
                src={iconFaqs}
                alt="icono-emociones"
                className={`align-self-center astronaut-icon ${styles.cardIcon}`}
              />
              <span className="align-self-end ps-2 pt-1">
                Preguntas frecuentes
              </span>
            </div>
          </div>
        </div>
      </div>
      <Body1 className="p-3">
        <Row className="d-flex align-items-center">
          <Col size="12" className="d-flex p-2 ps-3">
            <p className="align-self-center font-16 text-color-dark">
              En nuestra sección de Preguntas Frecuentes, encontrarás <br />{" "}
              respuestas a tus preguntas.
              <span className="font-medium text-blue-dtmood">
                {" "}
                ¡Estamos aquí para ayudarte!
              </span>
            </p>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col size="12" className="d-flex align-items-end">
            <div className="d-flex justify-content-end mt-3 w-100">
              <Button
                appearance="primary"
                className="mt-auto"
                onClick={() => setSelectedValue("preguntasFrecuentes")}
              >
                Tengo una duda
              </Button>
            </div>
          </Col>
        </Row>
      </Body1>
    </Card>
  );
};

export default PreguntasFrecuentesCard;
