import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Body1,
  Textarea,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";

import { Form, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import styles from "./SeleccionMotivos.module.css";
import useApi from "../../../../../hooks/useApi";

const SeleccionMotivos = (props) => {
  const { setStep, motivosSeleccionados, setMotivosSeleccionados, setModal } =
    props;
  const [misMotivos, setMisMotivos] = useState();
  const [dynamicRequired, setDynamicRequired] = useState(true);
  const { data, loading, getData } = useApi();
  const SkeletonArray = [1, 2, 3];
  const apiUrl = `/api/reasons/`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    reValidateMode: "onChange",
  });

  const watchComments = watch("comments");

  useEffect(() => {
    const val = !(watchComments && watchComments.trim() !== "");
    setDynamicRequired(val);

    watch((value, { name, type }) => console.log(value, name, type));
  }, [watch]);

  const [valid, setValid] = useState(false);

  const toggleValidState = () => {
    setValid(!valid);
  };

  useEffect(() => {
    getData(apiUrl);
    toggleValidState();
    setValid(true);
  }, []);

  useEffect(() => {
    if (motivosSeleccionados && data) {
      data.map((item, index) => {
        const found = motivosSeleccionados.misMotivos.find(
          (element) => element.id === item.id
        );
        if (found) {
          data[index].selected = true;
        }
        return item;
      });
      setValue("comments", motivosSeleccionados.comments);
    }
    setMisMotivos(data);
  }, [data]);

  const seleccionarMotivo = (index) => {
    misMotivos[index].selected = !misMotivos[index].selected;
  };

  const onSubmit = (data) => {
    const total = misMotivos.filter((item) => item.selected);
    setMotivosSeleccionados({
      misMotivos: total,
      comments: data.comments,
    });
    setStep(2);
  };

  return (
    <>
      <div className="flex-fill">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 2
        </p>

        <p className="font-16 font-medium text-center mb-3 mx-2 text-dark-dtmood">
          Selecciona los motivos relacionados <br /> a tus emociones de hoy
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-1">
            {loading &&
              SkeletonArray.map((item, index) => {
                return (
                  <Card className="w-100 mb-3">
                    <Body1>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-fill h-100">
                          <Skeleton>
                            <SkeletonItem size={16} className="my-2" />
                          </Skeleton>
                        </div>
                      </div>
                    </Body1>
                  </Card>
                );
              })}

            {misMotivos &&
              !loading &&
              misMotivos.map((item, index) => {
                return (
                  <FormGroup inline className="w-100" key={index}>
                    <Card className="w-100 mb-2">
                      <Body1 className="py-2 px-2">
                        <div
                          className={`d-flex align-items-center ${styles.checkDiv}`}
                        >
                          <input
                            name="reason"
                            type="checkbox"
                            id={item.id}
                            value={item.id}
                            onClick={() => seleccionarMotivo(index)}
                            {...register("reason", {
                              required: dynamicRequired,
                            })}
                            checked={item.selected}
                            onChange={() => {}}
                          />
                          <Label
                            htmlFor={item.id}
                            check
                            className="font-14 font-medium text-gray-dtmood ms-3"
                          >
                            {item.reason}
                          </Label>
                        </div>
                      </Body1>
                    </Card>
                  </FormGroup>
                );
              })}

            {errors.reason && errors.reason.type === "required" && (
              <div className="text-danger mb-4 mt-2">
                Selecciona o escribe algún un motivo
              </div>
            )}

            <FormGroup className="w-100">
              <Textarea
                name="comments"
                id="comments"
                cols="30"
                rows="4"
                placeholder="Puedes escribir otro motivo"
                {...register("comments", { maxLength: 300 })}
                className="mb-1 w-100"
              />
            </FormGroup>
            <div className="text-danger mt-0">
              {errors.comments &&
                errors.comments.type === "maxLength" &&
                "Máximo 300 caracteres"}
            </div>
          </div>

          <FormGroup className="text-center mt-4 mx-1">
            <div className="d-flex align-items-center justify-content-end">
              <Button
                appearance="primary"
                outline
                type="button"
                className="me-2"
                onClick={() => setModal(false)}
              >
                Regresar
              </Button>
              <Button appearance="primary" type="submit">
                Siguiente
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default SeleccionMotivos;
