import React from "react";
import { Card } from "@fluentui/react-components";

import iconoNoticiasEventos from "../../../../../assets/images/dt-mood/iconos/noticias-y-eventos.svg";
import style from "./NoticiasEventosCardHome.module.css";

const NoticiasEventosCardHome = () => {
  return (
    <Card className={`w-100 align-items-center cursor-pointer border-0 p-0`}>
      <div className={`d-flex flex-row w-100 p-3 ${style.cardBg}`}>
        <div className="w-100 d-flex">
          <img
            src={iconoNoticiasEventos}
            alt="iconoNoticiasEventos"
            className={`icon-card-dtmood align-self-center ${style.icono}`}
          />
          <span className="ms-2 align-self-center text-color-headings">
            Noticias y eventos
          </span>
        </div>
      </div>
    </Card>
  );
};

export default NoticiasEventosCardHome;
