import { useState } from "react";
import { Body1, Card } from "@fluentui/react-components";
import { ChevronDownRegular, ChevronUpRegular } from "@fluentui/react-icons";

import styles from "./Accordion.module.css";

const Accordion = (props) => {
  const { faq } = props;
  const [showForm, setShowForm] = useState();

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Card className={`w-100 p-0`}>
        <div
          className={`d-flex justify-content-between align-items-center py-3 cursor-pointer ${
            showForm ? styles.openBackground : ""
          }`}
          onClick={() => toggleForm()}
        >
          <span className="font-16 font-medium text-blue-dtmood align-self-center ms-3">
            {faq.question}
          </span>
          <span className="font-24 align-self-center">
            {!showForm && (
              <ChevronDownRegular className={`me-2 ${styles.arrowIcon}`} />
            )}
            {showForm && (
              <ChevronUpRegular className={`me-2 ${styles.arrowIcon}`} />
            )}
          </span>
        </div>

        {showForm && (
          <Body1 className="pt-0 pb-0 mb-0 ps-2 pe-2">
            <div className="d-flex justify-content-between align-items-center p-3">
              <div className={`font-14 ${styles.textAnswer}`}>
                <span dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </div>
            </div>
          </Body1>
        )}
      </Card>
    </>
  );
};

export default Accordion;
