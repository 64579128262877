import { Button } from "@fluentui/react-components";
import { FormularioFeedback } from "../../";

const CalificarExperiencia = (props) => {
  const { setStep, puntosSeleccionados } = props;

  return (
    <>
      <div className="flex-fill mb-3">
        <p className="font-16 font-medium text-left mb-3 text-gray-dtmood">
          Paso 2
        </p>

        <p className="font-14 font-bold text-center mb-3 ms-2 me-2 text-blue-dtmood">
          {puntosSeleccionados.name}
        </p>
        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 text-dark-dtmood">
          ¡Califica tu experiencia <br /> en la actividad que desees!
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 text-blue-dtmood">
          Haz clic para ver la encuesta
        </p>

        <div className="text-left mt-4">
          {puntosSeleccionados &&
            puntosSeleccionados.subcategories.map((item, index) => {
              return (
                <FormularioFeedback
                  key={index}
                  subcategory={item}
                  puntosSeleccionados={puntosSeleccionados}
                />
              );
            })}
        </div>

        <div className="text-left mt-4">
          <div className="d-flex align-items-center justify-content-end">
            <Button
              appearance="primary"
              type="button"
              onClick={() => setStep(1)}
            >
              Regresar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalificarExperiencia;
