import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  awardGotten: undefined,
};

export const AwardSlice = createSlice({
  name: "award",
  initialState,
  reducers: {
    ChangeAwardStatus: (state, action) => {
      state.awardGotten = action.payload;
    },
  },
});

export const { ChangeAwardStatus } = AwardSlice.actions;

export default AwardSlice.reducer;
