import { useContext, useEffect, useState } from "react";
import { Button, Body1, Card, Textarea } from "@fluentui/react-components";
import {
  AddCircleRegular,
  SubtractCircleFilled,
  CheckmarkFilled,
} from "@fluentui/react-icons";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import styles from "./FormularioEvaluacion.module.css";
import { responseValues } from "./response-values";
import useApi from "../../../../../hooks/useApi";
import { ContactPointsContext } from "../../../context";
import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";

const FormularioEvaluacion = (props) => {
  const { subcategory, puntosSeleccionados } = props;
  const [showForm, setShowForm] = useState();
  const values = [...responseValues];
  const [calificaciones, setCalificaciones] = useState(values);
  const [calificacionesSeleccionadas, setCalificacionesSeleccionadas] =
    useState();
  const [clicked, setClicked] = useState(false);
  const { data, loading, error, postData } = useApi();
  const { setContactPointsState } = useContext(ContactPointsContext);
  const dispatch = useDispatch();

  let userSession = useSelector((state) => state.authentication.userSession);
  const userName = userSession ? userSession.username : "";

  useEffect(() => {
    setCalificaciones(values);
    return () => {
      setShowForm(false);
      setCalificacionesSeleccionadas();
      setClicked(false);
      cleanSelection();
    };
  }, []);

  const toggleForm = () => {
    cleanSelection();
    setShowForm(!showForm);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: "onChange",
  });

  useEffect(() => {
    watch((value, { name, type }) => {});
  }, [watch]);

  useEffect(() => {
    if (data) {
      const state = data.award.id === "" ? "success" : "award";
      setContactPointsState({
        state,
        badge_id: data.award.badge_id,
      });
      dispatch(ChangeMiDia(false));
    }
    if (error) {
      setContactPointsState({
        state: "warning",
      });
    }
  }, [data, error]);

  const handleClick = (index) => {
    cleanSelection();
    calificaciones[index].selected = true;
    setCalificacionesSeleccionadas(calificaciones[index]);
  };

  const cleanSelection = () => {
    calificaciones.map((item) => (item.selected = false));
    setCalificacionesSeleccionadas();
  };

  const onSubmit = (data) => {
    setClicked(true);
    if (
      !calificacionesSeleccionadas ||
      calificacionesSeleccionadas.length === 0
    )
      return;

    const formData = formatData(data);
    postData(`/api/feedback/`, formData);
  };

  const formatData = (data) => {
    return {
      contact: puntosSeleccionados.id,
      user: userName,
      only_contact: true,
      subcategory: [
        {
          subcategory: subcategory.id,
          comments: data.comments,
          score: calificacionesSeleccionadas.value,
        },
      ],
    };
  };

  return (
    <>
      <Card className="w-100 mb-2 cursor-pointer" onClick={() => toggleForm()}>
        <Body1 className="py-0">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className={`font-16 font-medium ${
                showForm ? "text-blue-dtmood" : "text-gray-dtmood"
              }`}
            >
              {subcategory.subcategory}
            </div>
            {!showForm && <AddCircleRegular className={styles.icon} />}
            {showForm && <SubtractCircleFilled className={styles.icon} />}
          </div>
        </Body1>
      </Card>

      {showForm && (
        <div className="ps-3 pe-3">
          <p className="font-16 text-center mb-1 ms-2 me-2 mt-4 text-blue-dtmood">
            Califica del 1 al 5
          </p>
          <p className="font-14 font-medium text-center mb-3 ms-2 me-2 mt-2 text-dark-dtmood">
            {subcategory.subcategory}
          </p>

          <div className="d-flex flex-row justify-content-center mb-3">
            {calificaciones &&
              calificaciones.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex justify-content-center align-items-start me-1 cursor-pointer ${
                      item.selected
                        ? styles.valueCardSelected
                        : styles.valueCard
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    {item.selected && (
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                      >
                        <CheckmarkFilled className={styles.checkIcon} />
                      </div>
                    )}
                    {!item.selected && (
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                      >
                        {item.value}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="text-danger mt-0 mb-3">
            {(!calificacionesSeleccionadas ||
              calificacionesSeleccionadas.length === 0) &&
              clicked &&
              "Selecciona una calificación"}
            {error && "Ha ocurrido un error"}
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="w-100">
              <Textarea
                name="comments"
                id="comments"
                cols="30"
                rows="3"
                placeholder="Escribe tu comentario"
                {...register("comments", { maxLength: 300 })}
                className="mb-1 w-100"
              />
            </FormGroup>
            <div className="text-danger mt-0">
              {errors.comments &&
                errors.comments.type === "maxLength" &&
                "Máximo 300 caracteres"}
            </div>

            <FormGroup className="text-center mt-4 mb-5">
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  appearance="primary"
                  type="submit"
                  className="w-50"
                  disabled={loading}
                >
                  Enviar encuesta
                </Button>
              </div>
            </FormGroup>
          </Form>
        </div>
      )}
    </>
  );
};

export default FormularioEvaluacion;
