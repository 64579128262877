import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSession: undefined,
};

export const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    ChangeAuthentication: (state, action) => {
      state.userSession = action.payload;
    },
  },
});

export const { ChangeAuthentication } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
