import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Body1,
  Card,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Table } from "reactstrap";

import useApi from "../../../../hooks/useApi";
import astronautIcon from "../../../../assets/images/dt-mood/user-astronaut-solid.svg";
import styles from "./MisInsignias.module.css";

import { listaInsignias } from "../../mocks/lista-insignias";
import { ChangeMisInsignias } from "../../store/mis-insignias/MisInsigniasSlice";

const MisInsignias = () => {
  const awards = useSelector((state) => state.awards);
  const [awardsList, setAwardsList] = useState();
  const { data, loading, error, getData } = useApi();
  const [userAwardsList, setUserAwardsList] = useState();
  const isUpdatedMisInsignias = useSelector(
    (state) => state.misInsignias.isUpdatedMisInsignias
  );
  const dispatch = useDispatch();
  let userSession = useSelector((state) => state.authentication.userSession);
  const userName = userSession ? userSession.username : "";
  const apiUrl = `/api/award/${userName}/list/`;
  const SkeletonArray = [1, 2, 3, 4, 5];

  useEffect(() => {
    awards ? setAwardsList(awards) : setAwardsList(listaInsignias);
  }, []);

  useEffect(() => {
    if (data) {
      setUserAwardsList(data);
      dispatch(ChangeMisInsignias(true));
    }
    if (error) {
      setUserAwardsList([]);
    }
  }, [data, error]);

  useEffect(() => {
    getData(apiUrl);
  }, [awardsList]);

  useEffect(() => {
    if (!isUpdatedMisInsignias) {
      getData(apiUrl);
    }
  }, [isUpdatedMisInsignias]);

  const getObtainedAward = (badgeId) => {
    return userAwardsList.find((element) => element.badge_id === badgeId);
  };

  return (
    <Card className="w-100 p-0">
      <div className="p-3 border-bottom mb-0">
        <div className="container text-start">
          <div className="row align-items-center">
            <div className="col">
              <img
                src={astronautIcon}
                alt="icono-emociones"
                className={`align-self-center ${styles.titleIcon}`}
              />
              <span className="align-self-center ms-2">Mis insignias</span>
            </div>
          </div>
        </div>
      </div>
      <Body1 className="p-3">
        <div className="flex-fill">
          <p className="font-16">¡Actívate y sube de nivel!</p>

          <Table
            hover
            borderless
            className={`font-14 ${styles.tablaInsignias}`}
          >
            <thead>
              <tr className="border-bottom">
                <th className="font-medium align-middle">&nbsp;</th>
                <th className="font-medium align-middle">&nbsp;</th>
                <th className="font-medium align-middle">Nivel</th>
                <th className="font-medium align-middle">Interacciones</th>
                <th className="font-medium align-middle">Mis logros</th>
              </tr>
            </thead>

            {loading &&
              SkeletonArray.map((item, index) => {
                return (
                  <tr className="border-bottom" key={index}>
                    <td className="align-middle" colSpan={5}>
                      <Skeleton>
                        <SkeletonItem size={16} className="my-2" />
                      </Skeleton>
                    </td>
                  </tr>
                );
              })}

            {awardsList && userAwardsList && !loading && (
              <tbody>
                {awardsList.awards.map((item, index) => {
                  return (
                    <tr className="border-bottom" key={index}>
                      <td className="align-middle">
                        <img
                          src={item.image}
                          alt={item.badge}
                          className={`${styles.insigniaTabla} ${
                            !getObtainedAward(item.badge_id) ? "grey-scale" : ""
                          }`}
                        />
                      </td>
                      <td className="font-bold align-middle">{index + 1}</td>
                      <td className="font-bold align-middle">{item.badge}</td>
                      <td className="font-medium align-middle">
                        {item.description}
                      </td>
                      <td className="font-medium align-middle">
                        <span
                          className={`${styles.badgeDtmood} ${
                            getObtainedAward(item.badge_id)
                              ? styles.badgeDtmoodWon
                              : styles.badgeDtmoodPending
                          }`}
                        >{`${
                          getObtainedAward(item.badge_id)
                            ? "Ganaste"
                            : "Pendiente"
                        }`}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        </div>
      </Body1>
    </Card>
  );
};

export default MisInsignias;
