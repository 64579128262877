import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { awardsList } from "./awards-list";
import style from "./ModalInsigniaObtenida.module.css";
import partySound from "../../../../assets/sounds/mp3/party-celebration.mp3";

const ModalInsigniaObtenida = ({ celebrate, badgeId }) => {
  const [selectedBadge, setSelectedBadge] = useState();
  const [celebrationBackground, setCelebrationBackground] = useState();
  const [celebrationMessage, setCelebrationMessage] = useState();

  useEffect(() => {
    const wonBadge = awardsList.find(
      (item) => item.badge_id === Number(badgeId)
    );
    setModalType(badgeId);

    setSelectedBadge({
      badge: wonBadge.badge,
      image: wonBadge.image,
    });
  }, []);

  useEffect(() => {
    if (celebrate) {
      showCelebration();
    }
  }, [celebrate]);

  const setModalType = (badgeId) => {
    if (badgeId < 5) {
      setCelebrationBackground(style.bgConfetti);
      setCelebrationMessage({
        title: `¡Felicitaciones!`,
        description: `Te has ganado tu segunda insignia con el grado de`,
      });
    }

    if (badgeId >= 5) {
      setCelebrationBackground(style.bgStars);
      setCelebrationMessage({
        title: `¡Has logrado llegar a la luna!`,
        description: `Como resultado, te otorgamos la <br /> insignia Nº ${badgeId} y te nombramos`,
      });
    }
  };

  const showCelebration = () => {
    const audioControl = document.getElementById("partySound");
    audioControl.play();

    setTimeout(() => {
      audioControl.pause();
    }, 4000);
  };

  return (
    <>
      <Row
        className={"" + (celebrate ? celebrationBackground : "")}
        style={{ margin: "0" }}
      >
        <Col xs="12" sm="12" md="12" lg="12">
          <div className="d-flex w-100 mb-2">
            {selectedBadge && (
              <img
                src={selectedBadge.image}
                alt="icono-emociones"
                className={`m-auto ${style.insigniaModal}`}
              />
            )}
          </div>
        </Col>
        {celebrationMessage && (
          <Col xs="12" sm="12" md="12" lg="12">
            <div className="text-color-dark w-100">
              <div className="font-24 font-medium text-center">
                {celebrationMessage.title}
              </div>
              <div
                className="font-18 text-center"
                dangerouslySetInnerHTML={{
                  __html: celebrationMessage.description,
                }}
              />
              {selectedBadge && (
                <div className="font-18 font-bold text-center">
                  "{selectedBadge.badge}"
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>

      <audio controls id="partySound">
        <source
          src={partySound}
          type="audio/mp3"
          controls="controls"
          preload="none"
        />
      </audio>
    </>
  );
};

export default ModalInsigniaObtenida;
