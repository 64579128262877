const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiEndpoint: process.env.REACT_APP_FUNC_ENDPOINT,
  apiName: process.env.REACT_APP_FUNC_NAME,
  apiDtMood: process.env.REACT_APP_API_DTMOOD,
  microsoftClientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  tabEndpoint: process.env.REACT_APP_TAB_ENDPOINT,
};

export default config;
