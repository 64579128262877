import { useEffect, useState } from "react";
import { Body1, Card } from "@fluentui/react-components";
import { Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";

import trophy from "../../../../assets/images/dt-mood/awesome-trophy.svg";
import astronaut from "../../../../assets/images/dt-mood/user-astronaut-solid.svg";
import circlePlaySolid from "../../../../assets/images/dt-mood/iconos/circle-play-solid.svg";
import styles from "./PuntosAcumulados.module.css";
import useApi from "../../../../hooks/useApi";
import { ChangePuntosAcumulados } from "../../store/puntos-acumulados/PuntosAcumuladosSlice";

const PuntosAcumulados = () => {
  const [puntos, setPuntos] = useState();
  const [missingPoints, setMissingPoints] = useState();
  const goals = [30, 60, 90, 120, 150];
  const highestGoal = goals.at(-1);
  const { data, error, getData } = useApi();
  const apiUrl = `/api/profile/interactions/`;
  let isUpdatedPuntosAcumulados = useSelector(
    (state) => state.puntosAcumulados.isUpdatedPuntosAcumulados
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getData(apiUrl);
  }, []);

  useEffect(() => {
    if (!isUpdatedPuntosAcumulados) {
      getData(apiUrl);
    }
  }, [isUpdatedPuntosAcumulados]);

  useEffect(() => {
    const nearestAward = nextAward(puntos, goals);
    setMissingPoints(nearestAward);
  }, [puntos]);

  useEffect(() => {
    if (data) {
      setPuntos((data.interactions_count * 100) / highestGoal);
      const nearestAward = nextAward(puntos, goals);
      setMissingPoints(nearestAward);
      dispatch(ChangePuntosAcumulados(true));
    }
    if (error) {
      setPuntos(0);
    }
  }, [data, error]);

  const nextAward = (points, goals) => {
    let nearestAward = 0;
    const realpoints = points * (highestGoal / 100);
    if (realpoints >= highestGoal) return 0;

    for (const currentNumber of goals) {
      if (currentNumber > realpoints) {
        nearestAward = currentNumber;
        break;
      }
    }
    return nearestAward - realpoints;
  };

  const state = {
    series: [puntos],
    options: {
      chart: {
        height: 230,
        type: "radialBar",
      },
      colors: ["#28C66F"],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            name: {
              offsetY: 26,
              color: "#0E0815",
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "400",
            },
            value: {
              offsetY: -16,
              color: "#0E0815",
              fontSize: "42px",
              fontFamily: "Poppins",
              fontWeight: "500",
              show: true,
              formatter: function (val, opts) {
                return val * (highestGoal / 100);
              },
            },
          },
        },
      },
      labels: ["Puntos"],
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "curve",
        colors: undefined,
        width: 0.5,
        dashArray: 0,
      },
    },
  };

  return (
    <Card className="w-100 p-0">
      <div className="border-bottom p-3 mb-0">
        <div className="container text-start">
          <div className="row align-items-center">
            <div className="col">
              <img
                src={trophy}
                alt="icono-emociones"
                className="align-self-center astronaut-icon"
              />
              <span className="align-self-end ps-2 pt-1">
                Puntos acumulados
              </span>
            </div>
          </div>
        </div>
      </div>
      <Body1 className="p-3">
        <Row className="d-flex align-items-center h-100">
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex p-0">
            {puntos > 0 && (
              <div id="chart-puntos-acumulados" className="m-auto">
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="radialBar"
                  height={state.options.chart.height}
                />
              </div>
            )}
            {puntos === 0 && (
              <div className={`m-auto ${styles.circlePlaySolid}`}>
                <img src={circlePlaySolid} alt="circlePlaySolid" />
              </div>
            )}
          </Col>
          <Col xs="12" sm="12" md="6" lg="6" className="d-flex p-2">
            {missingPoints > 0 && (
              <p className="align-self-center font-18 text-color-dark">
                Te faltan <b>{missingPoints}</b> puntos para ganar tu próxima
                insignia. <br /> <b>¡Sigue adelante!</b>
                <img
                  src={astronaut}
                  alt="icono-emociones"
                  className={`align-self-center ms-1 ${styles.icon12}`}
                />
              </p>
            )}
            {missingPoints === 0 && (
              <p className="align-self-center font-18 text-color-dark">
                ¡<b>Eres un verdadero crack</b> por lograr todas las insignias
                en este período! <br />
                <b>¡Disfruta de tu merecido premio!</b>
              </p>
            )}
          </Col>
        </Row>
      </Body1>
    </Card>
  );
};

export default PuntosAcumulados;
