import Swal from 'sweetalert2';

import pitazo from '../../../assets/images/dt-mood/iconos/enviar-un-pitazo.svg';

const showPitazoSuccessAlert = (idPitazo) => {
  Swal.fire({
    title: '¡Hemos recibido un pitazo! <br /> Vamos a proceder con <br /> la investigación',
    text: `Número de caso: #${idPitazo}`,
    imageUrl: pitazo,
    imageWidth: 64,
    imageAlt: 'pitazo',
    confirmButtonText: 'Gracias',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title',
      htmlContainer: 'sweet-text-pitazo',
      image: 'mt-5',
    },
  });
};

const showPitazoWarningAlert = () => {
  Swal.fire({
    title: '¡Has alcanzado <br /> tu límite diario en denuncias diarias!',
    text: 'Vuelve mañana para seguir ganando puntos',
    confirmButtonText: 'Cerrar',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title mt-5',
      htmlContainer: 'sweet-text',
    },
  });
};

export { showPitazoSuccessAlert, showPitazoWarningAlert };
