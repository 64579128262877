import { configureStore } from "@reduxjs/toolkit";

import AuthenticationReducer from "../modules/auth/store/authentication/AuthenticationSlice";
import AwardSlice from "../modules/home/store/award/AwardSlice";
import MiDiaReducer from "../modules/home/store/mi-dia/MiDiaSlice";
import MisInsigniasSlice from "../modules/home/store/mis-insignias/MisInsigniasSlice";
import PuntosAcumuladosSlice from "../modules/home/store/puntos-acumulados/PuntosAcumuladosSlice";

export const store = configureStore({
  reducer: {
    authentication: AuthenticationReducer,
    award: AwardSlice,
    miDia: MiDiaReducer,
    misInsignias: MisInsigniasSlice,
    puntosAcumulados: PuntosAcumuladosSlice,
  },
});

export default store;
