import { useState } from "react";
import { CalificarExperiencia, SeleccionarPuntoContacto } from "../../";

const ContactPointsStepper = () => {
  const [puntosSeleccionados, setPuntosSeleccionados] = useState();
  const [step, setStep] = useState(1);

  return (
    <div className="w-100">
      {step === 1 && (
        <SeleccionarPuntoContacto
          setPuntosSeleccionados={setPuntosSeleccionados}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <CalificarExperiencia
          puntosSeleccionados={puntosSeleccionados}
          setStep={setStep}
        />
      )}
    </div>
  );
};

export default ContactPointsStepper;
