import Swal from 'sweetalert2';

import quickFeedback from '../../../assets/images/dt-mood/iconos/quick-feedback-alert.svg';

const showFeedbackSuccessAlert = () => {
  Swal.fire({
    title: '¡Gracias por <br /> calificar tu experiencia!',
    text: 'Esto nos permite estar más cerca',
    imageUrl: quickFeedback,
    imageWidth: 64,
    imageAlt: 'feedback',
    confirmButtonText: 'Bien hecho',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title',
      htmlContainer: 'sweet-text',
      image: 'mt-5',
    },
  });
};

const showFeedbackWarningAlert = () => {
  Swal.fire({
    title: '¡Has alcanzado <br /> tu límite diario en una categoría!',
    text: 'Vuelve mañana para seguir ganando puntos',
    confirmButtonText: 'Cerrar',
    customClass: {
      confirmButton: 'sweet-confirm-button mb-3',
      title: 'sweet-title mt-5',
      htmlContainer: 'sweet-text',
    },
  });
};

export { showFeedbackSuccessAlert, showFeedbackWarningAlert };
