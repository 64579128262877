const saveDtMoodSession = (
  key,
  timeExpire,
  officeId,
  username,
  clientId,
  email,
  first_name,
  last_name
) => {
  const dtmoodSession = {
    key,
    time_expire: timeExpire,
    officeId,
    username,
    clientId,
    email,
    first_name,
    last_name,
  };
  window.localStorage.setItem("dtmood_session", JSON.stringify(dtmoodSession));
};

const getDtMoodSession = () => {
  const dtmoodSession = window.localStorage.getItem("dtmood_session");
  return dtmoodSession ? JSON.parse(dtmoodSession) : null;
};

const removeDtMoodSession = () => {
  return window.localStorage.removeItem("dtmood_session");
};

export { saveDtMoodSession, getDtMoodSession, removeDtMoodSession };
