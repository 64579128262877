import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import {
  Body1,
  Card,
  Skeleton,
  SkeletonItem,
  MessageBar,
  MessageBarTitle,
  MessageBarBody,
} from "@fluentui/react-components";
import { QuestionCircleRegular } from "@fluentui/react-icons";

import useApi from "../../../../../hooks/useApi";
import Accordion from "../accordion/Accordion";

const PreguntasFrecuentes = () => {
  const { data: faqs, loading, error, getData: setFaqs } = useApi();

  let userSession = useSelector((state) => state.authentication.userSession);
  const clientId = userSession ? userSession.clientId : "";
  const url = `${process.env.REACT_APP_API_DTMOOD}/api/faqs/${clientId}/list/`;
  const skeletonData = [1, 2, 3, 4];

  useEffect(() => {
    setFaqs(url);
  }, []);

  return (
    <>
      <h4 className="mb-3">
        <QuestionCircleRegular className="text-blue-dtmood" /> Encuentra
        respuestas a tus dudas
      </h4>
      <Row className="pe-0 pb-2 me-1">
        {loading && (
          <>
            {skeletonData.map((item, index) => {
              return (
                <Col xs="12" sm="12" md="12" lg="12" className="me-0 pe-0">
                  <Card className={`w-100 p-0 mb-3`}>
                    <Body1 className="pt-0 pb-0 mb-0 ps-2 pe-2">
                      <div className="px-2 py-3">
                        <Skeleton>
                          <SkeletonItem />
                        </Skeleton>
                      </div>
                    </Body1>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
        {faqs &&
          faqs.length > 0 &&
          !loading &&
          faqs.map((item, index) => {
            return (
              <Col xs="12" sm="12" md="12" lg="12" className="me-0 pe-0">
                <div className="mb-3" key={index}>
                  <Accordion faq={item} />
                </div>
              </Col>
            );
          })}
      </Row>
      {error && (
        <Row className="pe-0 pb-2">
          <Col xs="6" sm="6" md="4" lg="3" className="d-flex">
            <MessageBar key="1" intent="error">
              <MessageBarBody>
                <MessageBarTitle>Error</MessageBarTitle>
                Error de conexión
              </MessageBarBody>
            </MessageBar>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PreguntasFrecuentes;
